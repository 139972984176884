import { VApp } from 'vuetify/lib/components/VApp';
import { VAppBar } from 'vuetify/lib/components/VAppBar';
import { VAppBarNavIcon } from 'vuetify/lib/components/VAppBar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VApp,[_c('vertical-nav-menu',{attrs:{"is-drawer-open":_vm.isDrawerOpen},on:{"update:isDrawerOpen":function($event){_vm.isDrawerOpen=$event},"update:is-drawer-open":function($event){_vm.isDrawerOpen=$event}}}),_c(VAppBar,{attrs:{"app":"","flat":"","absolute":"","color":"transparent"}},[_c('div',{staticClass:"boxed-container w-full"},[_c('div',{staticClass:"d-flex align-center mx-6"},[_c(VAppBarNavIcon,{staticClass:"d-block d-lg-none me-2",on:{"click":function($event){_vm.isDrawerOpen = !_vm.isDrawerOpen}}}),_c(VTextField,{staticClass:"app-bar-search flex-grow-0",attrs:{"rounded":"","dense":"","outlined":"","prepend-inner-icon":_vm.icons.mdiMagnify,"hide-details":""}}),_c(VSpacer),_c('theme-switcher'),_c(VBtn,{staticClass:"ms-3",attrs:{"icon":"","small":""}},[_c(VIcon,[_vm._v(" "+_vm._s(_vm.icons.mdiBellOutline)+" ")])],1),_c('app-bar-user-menu')],1)])]),_c(VMain,[_c('div',{staticClass:"app-content-container boxed-container pa-6"},[_vm._t("default")],2)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }