<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
          :src="require('@/assets/images/logos/logoo.png')"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title text--primary">
            NAKLIYE TAKIP
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5"
    >
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileDocumentMultiple"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group>
        <nav-menu-group
        title="Cari Bilgileri"
        :icon="icons.mdiHomeCity"
        >
        <nav-menu-link
        title="Ana Cari"
        :to="{ name: 'anacari' }"
        :icon="icons.mdiOfficeBuilding"
      ></nav-menu-link>
      <nav-menu-link
        title="Alt Cari"
        :to="{ name: 'altcari' }"
        :icon="icons.mdiWarehouse"
      ></nav-menu-link>
      <nav-menu-link
        title="Şube"
        :to="{ name: 'sube' }"
        :icon="icons.mdiOfficeBuildingMarker"
      ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        title="Vasıtalar"
        :icon="icons.mdiCarMultiple"
        >
        <nav-menu-link
        title="Araçlar"
        :to="{ name: 'vasita' }"
        :icon="icons.mdiTruck"
      ></nav-menu-link>
      <nav-menu-link
        title="Araç Tipleri"
        :to="{ name: 'vasitatip' }"
        :icon="icons.mdiCarInfo"
      ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        title="Tanımlar"
        :icon="icons.mdiTapeMeasure"
        >
        <nav-menu-link
        title="Palet Tipleri"
        :to="{ name: 'pallets' }"
        :icon="icons.mdiLayersTriple"
      ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        title="Sevkler"
        :icon="icons.mdiTruckDelivery"
        >
        <nav-menu-link
        title="Siparişler"
        :to="{ name: 'siparis' }"
        :icon="icons.mdiDolly"
      ></nav-menu-link>

      <nav-menu-link
        title="Sevkiyatlar"
        :to="{ name: 'sevk' }"
        :icon="icons.mdiTruckFast"
      ></nav-menu-link>

      </nav-menu-group>

      <nav-menu-group
        title="Malzemeler"
        :icon="icons.mdiPackageVariantClosed"
        >
        <nav-menu-link
        title="Malzemeler"
        :to="{ name: 'malzeme' }"
        :icon="icons.mdiSemanticWeb"
      ></nav-menu-link>
      </nav-menu-group>

      <nav-menu-group
        title="Kişiler"
        :icon="icons.mdiAccountMultiple"
        >
        <nav-menu-link
        title="Kullanıcılar"
        :to="{ name: 'kullanici' }"
        :icon="icons.mdiBadgeAccount"
      ></nav-menu-link>

      <nav-menu-link
        title="Şoförler"
        :to="{ name: 'sofor' }"
        :icon="icons.mdiAccountTie"
      ></nav-menu-link>
      </nav-menu-group>

      

      

      <nav-menu-section-title title="KÖPRÜLER"></nav-menu-section-title>

      <nav-menu-group
        title="Köprüler"
        :icon="icons.mdiAccountMultiple"
        >
        <nav-menu-link
        title="Malzemeler"
        :to="{ name: 'urunBridge' }"
        :icon="icons.mdiBadgeAccount"
      ></nav-menu-link>

      <nav-menu-link
        title="Siparisler"
        :to="{ name: 'siparisBridge' }"
        :icon="icons.mdiBadgeAccount"
      ></nav-menu-link>

      </nav-menu-group>


      <nav-menu-link
        title="Typography"
        :to="{ name: 'typography' }"
        :icon="icons.mdiAlphaTBoxOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Icons"
        :to="{ name: 'icons' }"
        :icon="icons.mdiEyeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Cards"
        :to="{ name: 'cards' }"
        :icon="icons.mdiCreditCardOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Tables"
        :to="{ name: 'simple-table' }"
        :icon="icons.mdiTable"
      ></nav-menu-link>
      <nav-menu-link
        title="Form Layouts"
        :to="{ name: 'form-layouts' }"
        :icon="icons.mdiFormSelect"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiSemanticWeb,
  mdiAccountMultiple,
  mdiBadgeAccount,
  mdiAccountTie,
  mdiPackageVariantClosed,
  mdiTruckFast,
  mdiDolly,
  mdiTruckDelivery,
  mdiTapeMeasure,
  mdiLayersTriple,
  mdiCarMultiple,
  mdiTruck,
  mdiCarInfo,
  mdiOfficeBuilding,
  mdiWarehouse,
  mdiOfficeBuildingMarker,
  mdiHomeCity,
  mdiFileDocumentMultiple,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  setup() {
    return {
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiSemanticWeb,
        mdiAccountMultiple,
        mdiBadgeAccount,
        mdiAccountTie,
        mdiPackageVariantClosed,
        mdiTruckFast,
        mdiDolly,
        mdiTruckDelivery,
        mdiTapeMeasure,
        mdiLayersTriple,
        mdiCarMultiple,
        mdiTruck,
        mdiCarInfo,
        mdiOfficeBuilding,
        mdiWarehouse,
        mdiOfficeBuildingMarker,
        mdiHomeCity,
        mdiFileDocumentMultiple,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
