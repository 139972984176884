import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/typography',
    name: 'typography',
    component: () => import('@/views/typography/Typography.vue'),
  },
  {
    path: '/icons',
    name: 'icons',
    component: () => import('@/views/icons/Icons.vue'),
  },
  {
    path: '/cards',
    name: 'cards',
    component: () => import('@/views/cards/Card.vue'),
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/form-layouts',
    name: 'form-layouts',
    component: () => import('@/views/form-layouts/FormLayouts.vue'),
  },
  {
    path: '/pages/account-settings',
    name: 'pages-account-settings',
    component: () => import('@/views/pages/account-settings/AccountSettings.vue'),
  },
  {
    path: '/pages/anacari',
    name: 'anacari',
    component: () => import('@/views/pages/anacari/AnaCari.vue'),
  },
  {
    path: '/pages/altcari',
    name: 'altcari',
    component: () => import('@/views/pages/altcari/AltCari.vue'),
  },
  {
    path: '/pages/malzeme',
    name: 'malzeme',
    component: () => import('@/views/pages/malzemeler/Malzeme.vue'),
  },
  {
    path: '/pages/sube',
    name: 'sube',
    component: () => import('@/views/pages/sube/Sube.vue'),
  },
  {
    path: '/pages/sevk',
    name: 'sevk',
    component: () => import('@/views/pages/sevkler/Sevk.vue'),
  },
  {
    path: '/pages/vasita',
    name: 'vasita',
    component: () => import('@/views/pages/vasitalar/Vasitalar.vue'),
  },
  {
    path: '/pages/vasitatip',
    name: 'vasitatip',
    component: () => import('@/views/pages/vasitalar/VasitaTip.vue'),
  },
  {
    path: '/pages/pallets',
    name: 'pallets',
    component: () => import('@/views/pages/pallets/Pallets.vue'),
  },
  {
    path: '/pages/sevkler',
    name: 'siparis',
    component: () => import('@/views/pages/sevkler/Siparis.vue'),
  },
  {
    path: '/kisiler/kullanici',
    name: 'kullanici',
    component: () => import('@/views/pages/kisiler/Kullanici.vue'),
  },
  {
    path: '/kisiler/sofor',
    name: 'sofor',
    component: () => import('@/views/pages/kisiler/Sofor.vue'),
  },
  {
    path: '/bridges/urunbridge',
    name: 'urunBridge',
    component: () => import('@/views/bridges/UrunBridge.vue'),
  },
  {
    path: '/bridges/siparisbridges',
    name: 'siparisBridge',
    component: () => import('@/views/bridges/SiparisBridge.vue'),
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('@/views/pages/Register.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/pages/label',
    name: 'pages-label',
    component: () => import('@/views/pages/OrderLabel.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
