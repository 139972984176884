import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import axios from 'axios'
import * as VueGoogleMaps from "vue2-google-maps" // Import package

axios.defaults.baseURL = 'https://api.monstersoft.com.tr/api/';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Authorization'] = `Bearer ${sessionStorage.getItem('token-value')}`;

Vue.config.productionTip = false

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyCI0DARwS0FxELpQ-OwZYVaX2_0hhfrnOQ",
    libraries: "places"
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
